module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-152750982-1","head":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bardem001","accessToken":"MC5YY3Q4R3hFQUFDTUF6c1dY.77-977-977-9Bu-_vRjvv71U77-977-977-9B00pM2Xvv73vv71WYe-_vXTvv70YeUh477-977-9be-_ve-_vQ","schemas":{"home":{"Main":{"opening_hours":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Opening Hours"}},"address_display_text":{"type":"StructuredText","config":{"multi":"paragraph","label":"Address Display Text"}},"address":{"type":"Link","config":{"label":"Address"}},"email_display_text":{"type":"StructuredText","config":{"multi":"paragraph","label":"Email Display Text"}},"email":{"type":"Link","config":{"label":"Email"}},"phone_number":{"type":"StructuredText","config":{"single":"paragraph","label":"Phone Number"}},"menu_display_text":{"type":"StructuredText","config":{"single":"paragraph","label":"Menu Display Text"}},"menu_link":{"type":"Link","config":{"label":"Menu Link"}}},"Scroller Images":{"desktop_images_left":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}}},"label":"Desktop Images Left"}},"desktop_images_right":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}}},"label":"Desktop Images Right"}},"mobile_images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}}},"label":"Mobile Images"}}}}}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
